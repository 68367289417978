<script lang="ts" setup>
import { SearchIcon } from '@cfgtech/icons'
import type { PropType } from 'vue'

const props = withDefaults(
  defineProps<{
    autofocus?: boolean
  }>(),
  {
    autofocus: false,
  },
)

const emit = defineEmits<{
  clear: []
  submit: [value: string]
}>()

const modelValue = defineModel<string>({
  default: '',
  type: String as PropType<string>,
})

function handleInputClear() {
  modelValue.value = ''
  emit('clear')
}
</script>

<template>
  <form @submit.prevent="$emit('submit', modelValue)">
    <FormInput
      v-bind="$attrs"
      v-model="modelValue"
      :autofocus="props.autofocus"
      clearable
      size="large"
      type="text"
      @clear="handleInputClear"
    >
      <template #icon>
        <SearchIcon />
      </template>
    </FormInput>
  </form>
</template>
