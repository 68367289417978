<script lang="ts" setup>
import { CfgPreloader } from '@cfgtech/ui'
import { TickIcon } from '@cfgtech/icons'

const props = withDefaults(defineProps<{
  modelValue?: string | number
  size?: 'normal' | 'large'
  clearable?: boolean
  loading?: boolean
  suffix?: string
  autofocus?: boolean
}>(), {
  size: 'normal',
  modelValue: '',
  suffix: '',
  autofocus: false,
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
  (event: 'clear'): void
}>()

const inputNode = templateRef<HTMLInputElement>('inputNode')

const suffixRef = templateRef<HTMLInputElement>('suffixRef')
const { width } = useElementBounding(suffixRef)
const suffixWidth = computed(() => `${width.value}px`)

defineExpose({
  focus() {
    if (inputNode.value) {
      inputNode.value.focus()
    }
  },
})

const showClearButton = computed(() => props.modelValue && props.clearable)
function onInputChanged(evt: InputEvent): void {
  emit('update:modelValue', (evt.target as HTMLInputElement).value)
}
function clearHandler(): void {
  emit('clear')
}

onMounted(() => {
  if (inputNode.value && props.autofocus) {
    inputNode.value.focus()
  }
})
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
  <span
    class="form--input relative block rounded-lg font-display text-sm text-grey-400"
    :class="{
      'with-suffix': !!suffix,
      'with-icon': Boolean($slots.icon),
      [`form--input--size-${size}`]: true,
      'clearable': showClearButton,
      loading,
    }"
    :style="{
      '--suffix-width': suffixWidth,
    }"
  >

    <span v-if="loading" class="input-preloader">
      <ClientOnly>
        <CfgPreloader class="text-[6px] text-brand" />
      </ClientOnly>
    </span>

    <!--  Mouseup use instead of click. its need to prevent generate click of enter press  -->
    <button
      v-else-if="showClearButton"
      class="form--input__clear absolute text-lg"
      tabindex="-1"
      type="button"
      @mouseup="clearHandler"
    >
      <ClientOnly>
        <TickIcon />
      </ClientOnly>
    </button>

    <label class="block rounded-[inherit]">
      <span class="hidden">{{ $attrs.placeholder }}</span>

      <input
        :id="String($attrs.id)"
        ref="inputNode"
        v-bind="$attrs"
        class="form--input__input focusable rounded-[inherit] border border-solid border-grey-stroke bg-white transition-colors duration-200 focus-visible:border-brand focus-visible:outline-0"
        :value="modelValue"
        @input="onInputChanged"
      >
    </label>

    <ClientOnly v-if="$slots.icon">
      <span class="form--input__icon absolute text-lg">
        <slot name="icon" />
      </span>
    </ClientOnly>

    <span
      v-if="suffix"
      ref="suffixRef"
      class="form--input__suffix"
    >
      {{ suffix }}
    </span>
  </span>
</template>

<style lang="scss" scoped>
  .form--input {
    --default-input-height: 2.5em;

    $input-height: var(--default-input-height);
    $input-space-x: var(--input-spacing-x, 1em);
    $input-space-y: var(--input-spacing-y, 0.5em);

    $icon-size: var(--input-icon-size, 0.7em);
    $icon-indent: calc(#{$icon-size} * 0.6);
    $gap: 0.7em;

    --spacing-top: #{$input-space-y};
    --spacing-bottom: #{$input-space-y};
    --spacing-left: #{$input-space-x};
    --spacing-right: #{$input-space-x};

    &.with-icon {
      --spacing-left: calc(
        #{$icon-size} + #{$icon-indent} + #{$input-space-x} + #{$gap}
      )
    }

    &.clearable, &.loading {
      --spacing-right: calc(
        #{$icon-size} + #{$input-space-x} + #{$gap}
      );
    }

    &.with-suffix {
      --spacing-right: calc(
          var(--suffix-width) + #{$input-space-x} + #{$gap}
      );
    }

    &--size {
      &-large {
        --default-input-height: 3.4rem;
      }
    }

    &__icon, &__icon-warning {
      --icon-size: #{$icon-size};

      @apply absolute -translate-y-1/2 text-grey-200 flex justify-center items-center;

      top: calc(#{$input-height / 2});
      left: $input-space-x;
    }

    &__icon-warning {
      @apply text-warning;

      left: calc(100% - #{$input-space-x} - #{$icon-size});
    }

    &__suffix {
      --icon-size: #{$icon-size};

      @apply absolute -translate-y-1/2 text-grey-200 flex justify-center items-center;

      top: calc(#{$input-height / 2});
      left: calc(100% - #{$input-space-x} - #{$icon-size});
    }

    &__clear, .input-preloader {
      --icon-size: #{$icon-size};
      @apply absolute -translate-y-1/2 text-grey-200;
      top: calc(#{$input-height / 2});
      right: $input-space-x;
    }

    &__input {
      padding: var(--spacing-top) var(--spacing-right) var(--spacing-bottom) var(--spacing-left);

      @apply w-full block;
      height: $input-height;

      &:focus-visible {
        & + .form--input__icon {
          @apply text-brand;
        }
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
</style>
